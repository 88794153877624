/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ufn4s8 --style3 js-anim  --anim7 --anim-s5 --full pb--10" anim={"7"} name={"einleitung"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"}>
              
              <Subtitle className="subtitle-box fs--30" content={"<span style=\"color: white;\">Residential & Commercial Painting</span>"}>
              </Subtitle>

              <Title className="title-box fs--128 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: white;\">Peter Nemec</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape3 fs--20" content={"Dienstleistungen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dienstleistungen"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2" anim={"2"} animS={"2"}>
              
              <Title className="title-box" content={"We paint residential and commercial buildings, interiors and exteriors."}>
              </Title>

              <Text className="text-box" style={{"maxWidth":800}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--4 mb--10 mt--40 pr--08" anim={"2"} animS={"2"} style={{"maxWidth":1000}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/11212/edd25672a25441b6a3a4f8c7c30f02a2.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(34,40,77,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Moving & Covering"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/11212/6d51b8b3d58d4cd0810434f6bb00404b.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(34,40,77,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Exterior Painting"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/11212/480613b7246544cab2d46cf9409f2b06.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(34,40,77,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Interior Painting"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/o/11212/719c2a7898324001aa78bd64a120ac11.svg"} sizes={"100vw"} style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(34,40,77,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Drywall Repair"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"referenzen"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: white;\">Referenzen</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 --left" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen.\"</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white; font-weight: bold;\">—   Jana Müller&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 --left" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen.\"</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white; font-weight: bold;\">—   Jana Müller&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-c5sjl8 pb--80 pt--80" name={"kontakt"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/148/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Kontaktieren Sie uns</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape3" content={"+49 797 811 2X05"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Peter Nemec</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Über uns</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Text>

              <Text className="text-box" content={"Firmen-Ident.-Nr.: 12345678<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}